<template>
  <div class="footer">
    <v-container>
      <div class="social_apps">
        <h1
          style="
            font-family: Outfit;
            font-weight: 400;
            margin-bottom: 30px;
            font-size: 2rem;
          "
        >
          {{ LanguageServ("lenoreApp") }}
        </h1>
        <a style="margin-right: 10px; display: inline-block">
          <img width="130" src="@/assets/footer/android.svg" alt="" />
        </a>
        <a>
          <img width="130" src="@/assets/footer/iphone.svg" alt="" />
        </a>
      </div>
      <div class="items d-grid">
        <div class="links">
          <h5 class="footer_header">{{ LanguageServ("footerGeneral") }}</h5>
          <ul>
            <li v-for="link in firstLinks" :key="link">
              <router-link :to="{ name: link.route }">
                {{ link.text }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="links">
          <h5 class="footer_header">{{ LanguageServ("brands") }}</h5>
          <ul>
            <li v-for="(link, i) in allBrands" :key="i" style="cursor: pointer">
              {{ link.name }}
            </li>
          </ul>
        </div>
        <div class="links">
          <h5 class="footer_header">
            {{ LanguageServ("followUs") }}
          </h5>
          <ul class="social_icons d-flex ga-2 mb-7">
            <li><v-icon>mdi-facebook</v-icon></li>
            <li><v-icon>mdi-instagram</v-icon></li>
            <li><v-icon>mdi-twitter</v-icon></li>
            <li><v-icon>mdi-youtube</v-icon></li>
            <li><v-icon>mdi-linkedin</v-icon></li>
          </ul>
          <h5 class="footer_header">PAYMENT METHODS</h5>
          <ul class="social_icons d-flex ga-2 flex-wrap">
            <li>
              <img
                style="height: 48px"
                src="@/assets/footer/apple.png"
                alt=""
              />
            </li>
            <li>
              <img src="@/assets/footer/masterCard.svg" alt="" />
            </li>
            <li>
              <img src="@/assets/footer/visa.svg" alt="" />
            </li>
          </ul>
        </div>
      </div>
      <div
        class="text-center content"
        style="font-size: 22px; padding-bottom: 15px; padding-top: 15px"
      >
        &copy; {{ new Date().getFullYear() }} {{ LanguageServ("rights") }}
      </div>
    </v-container>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import LanguageService from "@/stores/LanguageService";
import { brandsSt } from "@/stores/brands.js";
import { storeToRefs } from "pinia";
const { allBrands } = storeToRefs(brandsSt());

const LanguageServ = (text) => {
  return LanguageService.init().getLanguage().setFile("HOME").getText(text);
};
const firstLinks = ref([
  {
    text: "About us",
    route: "aboutUs",
  },
  {
    text: "Careers",
    route: "home",
  },
  {
    text: "FAQ",
    route: "home",
  },
  {
    text: "Contact us",
    route: "contactUs",
  },
  {
    text: "Partner with us",
    route: "home",
  },
  {
    text: "Refund & Return Policy",
    route: "refundPolicy",
  },
]);
onMounted(async () => {
  await brandsSt().getAllBrands();
});
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid rgb(9 141 143 / 48%);
  color: var(--cyan);
  font-family: Outfit;
  font-weight: 400 !important;
  background-color: var(--cyan);
  color: white;
  .items {
    padding: 50px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 520px) {
      grid-template-columns: 1fr;
    }
  }
  .links {
    li {
      margin-bottom: 8px;
      a {
        color: white;
        font-weight: 400 !important;
      }
    }
  }
  .footer_header {
    font-size: 24px;

    margin-bottom: 20px;
    font-weight: 400 !important;
  }
  input {
    outline: none;
    border: 1px solid var(--cyan);
    background: white;
    padding: 7px 15px;
    border-radius: 15px;
    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .submit {
    border-radius: 15px;
    color: white;

    font-weight: 800;
    text-transform: none;
  }
  .v-btn {
    font-weight: 400 !important;
  }
  .social_icons {
    li {
      cursor: pointer;
    }
  }
}
</style>
