import { createRouter, createWebHistory } from "vue-router";
import { checkCookie } from "@/stores/auth.js";
// import { storeToRefs } from "pinia";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      title: "Lenore - Home",
    },
  },
  {
    path: "/products",
    name: "AllProducts",
    component: () => import("@/views/AllProducts.vue"),
    meta: {
      title: "Lenore - Gift Sets",
    },
  },
  // {
  //   path: "/event-gifts/:id",
  //   name: "eventGifts",
  //   component: () => import("@/views/EventsGifts.vue"),
  //   meta: {
  //     title: "Lenore - Event Gifts",
  //   },
  // },
  {
    path: "/size-guide",
    name: "sizeGuide",
    component: () => import("@/views/SizeGuide.vue"),
    meta: {
      title: "Lenore - Size Guide",
    },
  },
  {
    path: "/sign-in",
    name: "signIn",
    component: () => import("@/views/sign_in/SignIn.vue"),
    meta: {
      title: "Lenore - Sign in",
    },
  },
  {
    path: "/sign-up",
    name: "signUp",
    component: () => import("@/views/sign_in/SignUp.vue"),
    meta: {
      title: "Lenore - Sign up",
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("@/views/sign_in/ForgotPassword.vue"),
    meta: {
      title: "Lenore - Reset Password",
    },
  },
  {
    path: "/verification-code/:token",
    name: "enterCode",
    component: () => import("@/views/sign_in/EnterCode.vue"),
    meta: {
      title: "Lenore - Reset Password",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/ProfilePage.vue"),
    children: [
      {
        path: "",
        redirect: "/profile/details",
        name: "profileRedirect", // Adding a name to the redirect route
      },
      {
        path: "details",
        name: "profileDetails",
        component: () => import("@/components/profile/ProfileDetails.vue"),
        meta: {
          title: "Lenore - Profile",
        },
      },
      {
        path: "change-password",
        name: "changePassword",
        component: () => import("@/components/profile/ChangePassword.vue"),
        meta: {
          title: "Lenore - Password",
        },
      },
      {
        path: "my-orders",
        name: "viewOrders",
        component: () => import("@/components/profile/MyOrders.vue"),
        meta: {
          title: "Lenore - Orders",
        },
      },
    ],
  },
  {
    path: "/product/:id",
    name: "productDetails",
    component: () => import("@/views/ProductDetails.vue"),
    meta: {
      title: "Lenore - Product",
    },
  },
  {
    path: "/brand/:id",
    name: "brandProducts",
    component: () => import("@/views/BrandProducts.vue"),
    meta: {
      title: "Lenore - Brand",
    },
  },
  {
    path: "/shopping-cart",
    name: "cartPage",
    component: () => import("@/views/CartPage.vue"),
    meta: {
      title: "Lenore - Product",
    },
  },
  {
    path: "/purchase-details/:id",
    name: "purchasedPage",
    component: () => import("@/views/PurchasedPage.vue"),
    meta: {
      title: "Lenore - Purchases",
    },
  },
  {
    path: "/about-us",
    name: "aboutUs",
    component: () => import("@/views/AboutUs.vue"),
    meta: {
      title: "Lenore - About us",
    },
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: () => import("@/views/ContactUs.vue"),
    meta: {
      title: "Lenore - Contact us",
    },
  },
  {
    path: "/my-wishlist",
    name: "wishlistPage",
    component: () => import("@/views/wishlist/WishlistPage.vue"),
    meta: {
      title: "Lenore - Contact us",
    },
  },
  {
    path: "/my-orders",
    name: "myOrders",
    component: () => import("@/views/MyOrders.vue"),
    meta: {
      title: "Lenore - Contact us",
    },
  },
  {
    path: "/refund-policy",
    name: "refundPolicy",
    component: () => import("@/views/RefundPolicy.vue"),
    meta: {
      title: "Lenore - Refund",
    },
  },
  // 404 Page
  {
    name: "NotFound",
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/NotFound"),
    meta: {
      title: "404-NotFound",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});
// Change The Page Title Dynamically
router.beforeEach((to) => {
  document.title = to.meta.title;
});

router.beforeEach(async (to, from, next) => {
  if (to.name != "signIn") {
    localStorage.setItem("currentUrl", to.fullPath);
  }
  const cookie = decodeURIComponent(document.cookie);
  if (cookie) {
    if (!checkCookie().user) {
      const res = await checkCookie().getUserData(
        JSON.parse(cookie.split("=")[1]).token
      );
      if (res) {
        if (to.name == "signIn" || to.name == "signUp")
          return next(
            to.name == "signIn" || to.name == "signUp" ? "/" : to.fullPath
          );
        return next(
          to.name == "signIn" || to.name == "signUp" ? "/" : to.fullPath
        );
      } else {
        return to.path.includes("profile") ? next({ name: "signIn" }) : next();
      }
    }
  } else {
    if (to.path.includes("profile")) return next({ name: "signIn" });
  }
  next();
});
export default router;
