export default {
  size: "دليل مقاسات-الخواتم",
  note: ".لست متأكدًا من المقاس الذي يجب عليك اختياره؟ تابع القراءة لتكتشف كيفية العثور على المقاس الصحيح",
  have: "اتستخدم الخاتم الذي لديك بالفعل",
  haveOne: "اختار الخاتم الذي يناسبك بالفعل",
  haveTwo: "قم بقياس القطر الداخلي بالملليمتر",
  haveThree: "استخدم الجدول ادناه للحصول علي اول خاتم من لينور",
  tips: "! نصائح",
  tipsOne:
    "قد يختلف حجم أصابعك حسب الوقت من اليوم. للحصول على نتيجة موثوقة قدر الإمكان، نوصيك بقياس أصابعك في أوقات مختلفة من اليوم.",
  tipsTwo:
    "لا تقم بقياس أصابعك عندما تكون باردة أو مبللة لأن أصابعك سوف تنكمش.",
  diameter: "القطر",
  ringSize: "المقاس",
  mm: "مم",
  wrist: "قم بقياس معصمك",
  wrap: "لف شريط القياس حول معصمك حيث سيكون السوار.",
  measure: "حدد مكان شريط القياس.",
  use: "استخدم الجدول أدناه للعثور على سوار لينور الجديد الخاص بك.",
  tipsBelow: "هل أنت محتار بين حجمين؟ ننصحك باختيار الحجم الأكبر بينهما.",
  cm: "سم",
  wristWord: "الرسغ",
  bracelet: "سوار",
  sizeWord: "المقاس",
};
