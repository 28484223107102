export default {
  login: `تسجيل دخول`,
  switchLang: "English",
  wishlist: "رغباتك",
  basket: "السلة",
  products: "المنتجات",
  ourStores: "متاجرنا",
  events: "المناسبات",
  lookingFor: "ما الذي تبحث عنه؟",
  welcome: "!مرحباً",
  seeNow: "أنظر الان",
  giftByEvents: "المناسبات",
  viewAll: "انظر الكل",
  welcomeUser: "مرحبا",
  // Footer
  lenoreApp: "اكتشف تطبيق لينور الجديد",
  footerGeneral: "تعرف علينا",
  occasions: "المناسبات",
  followUs: "تابعنا علي",
  rights: "لينور, جميع الحقوق محفوظة",
  enterEmail: "أدخل بريدك الإلكتروني",
  submit: "ارسال",
  brands: "البراندات",
  // Side menu
  guest: "زائر",
  sizeGuide: "دليل المقاسات",
  aboutUs: "معلومات عنا",
  myOrders: "طلباتي",
  contactUs: "تواصل معنا",
  home: "الرئيسية",
  myCart: "سلتي",
  myWishlist: "رغباتي",
  myProfile: "صفحتي",
  // Contact Us
  feelFree:
    "لا تتردد في التواصل معنا في أي وقت. سوف نقوم بالرد عليك في أقرب وقت ممكن!",
  name: "الاسم",
  email: "البريد الالكتروني",
  message: "الرسالة",
  sendEmail: "ارسال",
};
