export default {
  // Orders
  yourOrders: "Your Orders",
  empty: "Your orders list is empty!",
  continueShopping: "Continue Shopping",
  orderLoginFirst: "You haven't logged in yet to view your orders.",
  login: "Login",
  signUp: "Sign up",
  order: "Order",
  products: "Products",
  qrInTotal: "QR in Total",

  // Wishlist
  yourWishtlist: "Your Wishlist",
};
