<template>
  <div class="added_to_cart">
    <v-dialog v-model="dialog" width="auto" class="img_modal">
      <v-card
        style="
          padding: 50px 10px 110px;
          border: 1px solid var(--cyanMid);
          border-radius: 8px;
        "
      >
        <v-card-title class="px-0">
          <div class="dialog_parent" style="background-color: white">
            <h2 style="text-align: center; color: red; font-size: 19px">
              Something went wrong!<br />
              Invalid payment method
            </h2>
          </div>
        </v-card-title>
        <v-card-text class="pt-0 d-flex justify-center" style="gap: 12px">
          <div>
            <v-btn
              elevation="0"
              style="
                text-transform: none;
                background-color: red;
                color: white;
                border-radius: 7px;
                height: 31px;
              "
              @click="dialog = false"
            >
              Close
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps, defineEmits, watch } from "vue";
const dialog = ref(false);
const props = defineProps(["dialog"]);
const emits = defineEmits(["closePopup"]);

watch(
  () => dialog.value,
  (newVal) => {
    if (!newVal) {
      setTimeout(() => {
        emits("closePopup");
      }, 10);
    }
  }
);

onMounted(() => {
  dialog.value = props.dialog;
});
</script>
<style lang="scss" scoped>
.dialog_parent {
  color: var(--cyanMid);
  font-weight: 700;
  background-color: white;
}
</style>
