import ar from "../lang/ar";
import en from "../lang/en";

class LanguageService {
  langString = "en";

  static init() {
    return new LanguageService();
  }

  static currentLanguage() {
    return this.init().getLanguage().langString;
  }

  switchLanguage() {
    return this.setLanguage(
      this.getLanguage().langString == "en" ? "ar" : "en"
    );
  }

  getLanguage() {
    this.langString = localStorage.getItem("lenore_language") || "en";
    this.defaultLanguage = this.langString == "ar" ? ar : en;
    return this;
  }

  setLanguage(lang) {
    localStorage.setItem("lenore_language", lang);
    this.defaultLanguage = lang == "ar" ? ar : en;
    return this;
  }

  setFile(filename) {
    this.filename = filename;
    return this;
  }

  getText(key) {
    return this.defaultLanguage[this.filename][key] || "";
  }
}

export default LanguageService;
