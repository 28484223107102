import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
const app = createApp(App);

// Pinia Config
import { createPinia } from "pinia";
// Vuetify
import "vuetify/styles";
import "vuetify/dist/vuetify.min.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";
// Swiper
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// FontAwesome
import "@fortawesome/fontawesome-free/css/all.min.css";
import "vue-select/dist/vue-select.css";
import "vuetify/dist/vuetify.min.css";
// Main CSS File
import "swiper/css";
import "swiper/swiper-bundle.min.css";
import "@/scss/style.css";
// Fontawesome
import "@fortawesome/fontawesome-free/css/all.css";
// FlatPicker

import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
// Emitter (mitt) Config
import mitt from "mitt";

const emitter = mitt();
const vuetify = createVuetify({
  components,
  directives,
});

app
  .use(createPinia())
  .use(vuetify)
  .use(router)
  .use(require("vue-cookies"))
  .provide("emitter", emitter)
  .mount("#app");
