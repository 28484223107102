export default {
  login: "Login",
  switchLang: "العربية",
  wishlist: "Wishlist",
  basket: "Basket",
  products: "Products",
  ourStores: "Our Stores",
  events: "Events",
  lookingFor: "What are you looking for?",
  welcome: "Welcome!",
  seeNow: "See Now",
  giftByEvents: "Gift by Events",
  viewAll: "View All",
  welcomeUser: "Welcome",
  // Footer
  lenoreApp: "Discover Lenore's new app",
  footerGeneral: "Get to know us",
  occasions: "Occasions",
  followUs: "Follow us",
  rights: "Lenore, All Rights Reserved",
  enterEmail: "Enter your email",
  submit: "Submit",
  brands: "Brands",
  // Side Menu
  home: "Home",
  myCart: "My Cart",
  myWishlist: "My Wishlist",
  guest: "Guest",
  sizeGuide: "Size Guide",
  aboutUs: "About Us",
  myOrders: "My Orders",
  contactUs: "Contact us",
  feelFree:
    "Feel free to contact us any time. We will get back to you as soon as we can!",
  name: "Name",
  email: "Email",
  message: "Message",
  sendEmail: "Send Email",
  myProfile: "View Profile",
};
