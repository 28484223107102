export default {
  size: "Size Guide-Rings",
  note: "Not sure which size to choose? Read on to discover how to find the correct size",
  have: "Use a ring that you already have",
  haveOne: "Choose a ring that already fits you.",
  haveTwo: "Measure the inner diameter in millimeters.",
  haveThree: "Use the table below to find your new LINORE ring.",
  tips: "Tips!",
  tipsOne:
    "The size of your fingers can vary depending on the time of day. To get as reliable a result as possible , we recommend that you measure your fingers at different times during the day.",
  tipsTwo:
    "Don't measure your fingers when they're cold or wet as your fingers will have shrunk.",
  diameter: "Diameter",
  ringSize: "Size",
  mm: "mm",
  wrist: "Measure your wrist",
  wrap: "Wrap a measuring tape around your wrist where the bracelet will be.",
  measure: "Mark where the measuring tape meets.",
  use: "Use the table below to find your new LINORE Bracelet.",
  tipsBelow:
    "Are you in between two sizes ? We recommend you choose the larger of the two.",
  cm: "cm",
  wristWord: "Wrist",
  bracelet: "Bracelet",
  sizeWord: "Size",
};
