<template>
  <div style="display: flex; flex-direction: column; min-height: 100vh">
    <NavbarView
      v-if="
        $route.name != 'signIn' &&
        $route.name != 'signUp' &&
        $route.name != 'NotFound' &&
        $route.name != 'forgotPassword' &&
        $route.name != 'enterCode'
      "
    />
    <div style="flex: 1">
      <router-view />
    </div>
    <FooterView
      v-if="
        $route.name != 'signIn' &&
        $route.name != 'signUp' &&
        $route.name != 'NotFound' &&
        $route.name != 'forgotPassword' &&
        $route.name != 'enterCode'
      "
    />
  </div>
  <AddedToCart
    :dialog="showModal"
    v-if="showModal"
    @closePopup="showModal = false"
    :productDetails="productDetails"
    :addedTo="addedTo"
  />
  <LoginFirst
    :dialog="loginFirst"
    v-if="loginFirst"
    @closePopup="loginFirst = false"
  />
  <ErrorMessage :dialog="ErrMsg" v-if="ErrMsg" @closePopup="ErrMsg = false" />
  <!-- <LoadingOverlay v-if="isLoading" /> -->
</template>
<script setup>
import { ref, inject, onMounted } from "vue";
import NavbarView from "@/components/navbar/NavbarView.vue";
import FooterView from "@/components/footer/FooterView.vue";
import AddedToCart from "@/components/global/AddedToCart.vue";
import LoginFirst from "@/components/global/LoginFirst.vue";
import ErrorMessage from "@/components/global/ErrorMessage.vue";
// import LoadingOverlay from "@/views/LoadingOverlay.vue";
// import { useRouter } from "vue-router";
// Init Emitter
const emitter = inject("emitter");

// Data
const productDetails = ref("");
const addedTo = ref("");
const showModal = ref(false);
const loginFirst = ref(false);
const ErrMsg = ref(false);
// const router = useRouter();
// const isLoading = ref(false);

emitter.on("globalEmit", (data) => {
  productDetails.value = data.data;
  addedTo.value = data.addedTo;
  showModal.value = true;
});

emitter.on("loginFirst", () => {
  loginFirst.value = true;
});
emitter.on("errorMessage", () => {
  ErrMsg.value = true;
});

// const startLoading = () => {
//   isLoading.value = true;
//   setTimeout(() => {
//     isLoading.value = false;
//   }, 500);
// };

onMounted(() => {
  // router.beforeEach((to, from, next) => {
  //   startLoading();
  //   next();
  // });
});
</script>
<style lang="scss">
body {
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--cyan);
    transition: 0.3s;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(1, 107, 109);
  }
  &::-webkit-scrollbar-corner {
    background-color: white;
    box-shadow: 0 0 10px #ddd inset;
  }
}
.custom-skeleton-loader {
  /* Add your custom styles here */
  background-color: #f0f0f0; /* Example background color */
  border-radius: 8px; /* Example border radius */
  margin-right: 24px; /* Example gutter */
  width: 80px; /* Example button width */
}
.v-badge__badge {
  border-radius: 50%;
  width: 23px;
  height: 23px;
}
input[type="radio"] {
  accent-color: var(--cyan) !important;
}
</style>
