import home from "@/lang/en/home";
import orders from "@/lang/en/orders";
import about from "@/lang/en/about";
import SizeGuide from "@/lang/en/SizeGuide";

export default {
  HOME: home,
  ORDERS: orders,
  ABOUT: about,
  SIZE: SizeGuide,
};
