import home from "@/lang/ar/home";
import orders from "@/lang/ar/orders";
import about from "@/lang/ar/about";
import sizeGuide from "@/lang/ar/sizeGuide";

export default {
  HOME: home,
  ORDERS: orders,
  ABOUT: about,
  SIZE: sizeGuide,
};
