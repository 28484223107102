import { defineStore } from "pinia";

export const cartStore = defineStore("cartStore", {
  state: () => ({
    cartItems: [],
  }),
  actions: {
    // New Cart
    setLocalStorage() {
      localStorage.setItem("lenoreCartItems", JSON.stringify(this.cartItems));
    },
    addToCart(item) {
      let inCart = null;
      if (this.cartItems.length) {
        for (let i = 0; i < this.cartItems.length; i++) {
          let e = this.cartItems[i];
          if (e.id === item.id) {
            inCart = i;
            break;
          }
        }
      }
      if (!item.number) item.number = 1;
      if (inCart !== null) {
        this.cartItems[inCart].number++;
      } else {
        this.cartItems.push(item);
      }
      this.setLocalStorage();
    },
    removeItem(item) {
      this.cartItems.splice(this.cartItems.indexOf(item), 1);
      this.setLocalStorage();
    },
    increaseItems(item) {
      if (item.number < parseInt(item.quantity)) {
        item.number++;
      }
      this.setLocalStorage();
    },
    decreaseNum(item) {
      if (item.number > 1) {
        item.number--;
      }
      this.setLocalStorage();
    },
    getCartItems() {
      if (localStorage.getItem("lenoreCartItems")) {
        this.cartItems = JSON.parse(localStorage.getItem("lenoreCartItems"));
      }
    },
  },
});
