<template>
  <div class="main_navbar position-relative" style="z-index: 99">
    <div
      :class="`${$route.name != 'home' ? '' : 'position-absolute'}`"
      style="width: 100%; height: 100%; top: 0; left: 0"
    >
      <div
        style="background-color: var(--cyan); transition: transform 0.3s"
        :class="`navbar_parent ${windowHeight >= 400 ? 'fixed' : ''}`"
      >
        <v-container>
          <div class="home-content">
            <div
              class="content_parent d-flex justify-space-between align-center"
            >
              <div class="d-flex align-center flex-1-1 w-100">
                <router-link to="/">
                  <img
                    width="90"
                    src="@/assets/about_us/logo_home.png"
                    alt=""
                  />
                </router-link>
                <div class="search_input position-relative">
                  <i
                    class="fa-solid fa-magnifying-glass"
                    style="
                      color: grey;
                      opacity: 0.7;
                      position: absolute;
                      top: 50%;
                      left: 10px;
                      transform: translateY(-50%);
                    "
                  ></i>
                  <input
                    type="text"
                    :placeholder="languageServ('lookingFor')"
                    class="w-100"
                  />
                </div>
              </div>
              <div
                class="login_parent"
                style="
                  display: flex;
                  align-items: center;
                  color: white;
                  font-family: Outfit;
                "
              >
                <div
                  style="
                    padding: 0 10px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    white-space: nowrap;
                    text-transform: uppercase;
                    font-size: 14px;
                  "
                  @click="router.push({ name: 'profileRedirect' })"
                  v-if="user?.data?.first_name"
                >
                  <i class="fa-regular fa-user"></i>
                  <span :style="`direction: ${getLang ? 'rtl' : ''}`">
                    {{ languageServ("welcomeUser") + ", " }}
                    {{ user?.data?.first_name }}
                  </span>
                </div>
                <div
                  style="
                    padding: 0 10px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    white-space: nowrap;
                  "
                  @click="router.push({ name: 'signIn' })"
                  v-else
                >
                  <i class="fa-regular fa-user"></i>
                  {{ languageServ("login") }}
                </div>
                |
                <div style="padding: 0 10px">
                  <div
                    style="
                      font-family: Outfit;
                      cursor: pointer;
                      font-size: 14px;
                    "
                    @click="switchLanguage()"
                  >
                    {{ languageServ("switchLang") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </div>
      <div style="background-color: var(--cyan)">
        <v-container>
          <div
            class="d-flex"
            style="margin-left: 17px; background-color: var(--cyan)"
          >
            <div
              class="menu_icon"
              @click="toggleMenu()"
              style="display: flex; align-items: center"
            >
              <img
                style="width: 22px; cursor: pointer"
                src="@/assets/svgs/navbar/menu.svg"
                alt="img"
              />
            </div>
            <ul class="d-flex ga-5 flex-1-1 menu_list">
              <li>
                <router-link :to="{ name: 'AllProducts' }" style="color: white">
                  {{ languageServ("products") }}
                </router-link>
              </li>
              <li style="color: white">Brands</li>
              <li style="color: white">
                <a style="color: white">{{ languageServ("ourStores") }}</a>
              </li>
              <li style="color: white">
                {{ languageServ("events") }}
              </li>
            </ul>

            <div class="d-flex ga-5" style="cursor: pointer">
              <div
                class="d-flex"
                @click="router.push({ name: 'wishlistPage' })"
              >
                <p style="color: white">{{ languageServ("wishlist") }}</p>
                <img
                  class="ml-2"
                  src="@/assets/svgs/navbar/heart.svg"
                  alt="svg"
                  style="width: 19px; cursor: pointer"
                />
              </div>
              <div
                class="d-flex"
                @click="$route.name === 'cartPage' ? false : (openModal = true)"
              >
                <p style="color: white">{{ languageServ("basket") }}</p>
                <v-badge
                  class="cart_icon"
                  :content="totalItems <= 9 ? totalItems : '9+'"
                  color="var(--pink)"
                  style="display: flex; align-items: center"
                >
                  <img
                    class="ml-2"
                    src="@/assets/svgs/navbar/cart.svg"
                    alt="svg"
                    style="width: 24px; cursor: pointer"
                  />
                </v-badge>
              </div>
            </div>
          </div>
        </v-container>
      </div>
    </div>
  </div>
  <div class="menu-links">
    <div class="overlay" ref="menuOverlay" @click="toggleMenu()"></div>
    <div class="home-links d-flex flex-column" ref="menuLinks">
      <div class="menu_logo text-center" style="margin-bottom: 30px">
        <router-link to="/" @click="toggleMenu()">
          <img
            style="width: 50px"
            src="@/assets/home/menu_logo.svg"
            alt="Lenore"
          />
        </router-link>
      </div>
      <div
        class="login_info d-flex align-center"
        style="
          gap: 15px;
          color: var(--cyan);
          padding-bottom: 30px;
          border-bottom: 1px solid var(--cyan);
        "
      >
        <div>
          <img
            style="width: 60px; cursor: pointer"
            src="@/assets/home/login_icon.svg"
            alt="Lenore"
          />
        </div>
        <div>
          <h3>
            {{
              user && user.data && user.data.name
                ? user.data.name && user.data.name.split(" ")[0]
                : languageServ("guest")
            }}
          </h3>
          <router-link
            v-if="user && user.data && user.data.name"
            @click="toggleMenu()"
            style="color: var(--cyan)"
            to="/profile"
          >
            <p style="font-size: 15px; cursor: pointer">
              {{ languageServ("myProfile") }}
            </p>
          </router-link>
          <router-link
            v-else
            @click="toggleMenu()"
            style="color: var(--cyan); text-decoration: underline"
            :to="{ name: 'signIn' }"
          >
            <p style="font-size: 15px; cursor: pointer">Sign in</p>
          </router-link>
        </div>
      </div>
      <ul style="flex: 1">
        <li v-for="link in sideLinks" :key="link.id">
          <div @click="toggleLinks(link)">
            <div>
              <img :src="link.img" alt="Sidemenu Link" />
            </div>
            <div>{{ link.title }}</div>
          </div>
        </li>
        <li style="text-align: center" v-if="!user">
          <router-link
            style="
              display: inline-block;
              font-size: 14px;
              text-decoration: underline;
              margin-right: 10px;
              color: var(--cyan);
            "
            :to="{ name: 'signUp' }"
            @click="toggleMenu()"
          >
            Sign up
          </router-link>
          <router-link
            style="
              display: inline-block;
              font-size: 14px;
              text-decoration: underline;
              color: var(--cyan);
            "
            :to="{ name: 'signIn' }"
            @click="toggleMenu()"
          >
            Login
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <CartMenu @closeModal="openModal = false" :openModal="openModal" />
</template>
<script setup>
import { ref, nextTick, onMounted, computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { cartStore } from "@/stores/addToCart.js";
import { checkCookie } from "@/stores/auth.js";
import LanguageService from "@/stores/LanguageService";
import CartMenu from "@/components/navbar/CartMenu.vue";
import { useRouter } from "vue-router";

// Init Store
const cartModule = cartStore();
const loginSt = checkCookie();
const { user } = storeToRefs(loginSt);
const cookie = decodeURIComponent(document.cookie);

// Emitter
const emitter = inject("emitter");

// Data
const isMenuOpen = ref(false);
const menuOverlay = ref(null);
const menuLinks = ref(null);
const openModal = ref(false);
const { cartItems } = storeToRefs(cartModule);
const windowHeight = ref(0);
const getLang = ref(false);
const router = useRouter();
let languageServ = (txt) => {
  return LanguageService.init().getLanguage().setFile("HOME").getText(txt);
};

let switchLanguage = () => {
  LanguageService.init().switchLanguage();
  window.location.reload();
};

// Computed
const totalItems = computed(() => {
  let total = 0;
  if (cartItems.value.length) {
    cartItems.value.forEach((el) => (total += el.number));
  }
  return total;
});

// Methods
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
  if (isMenuOpen.value) {
    nextTick(() => {
      menuOverlay.value.classList.add("show");
      menuLinks.value.classList.add("show");
    });
  } else {
    menuLinks.value.classList.remove("show");
    menuOverlay.value.classList.remove("show");
  }
};

const toggleLinks = async (link) => {
  isMenuOpen.value = !isMenuOpen.value;
  if (isMenuOpen.value) {
    nextTick(() => {
      menuOverlay.value.classList.add("show");
      menuLinks.value.classList.add("show");
    });
  } else {
    menuLinks.value.classList.remove("show");
    menuOverlay.value.classList.remove("show");
  }

  if (link.route == "purchasedPage") {
    if (cookie) {
      await loginSt.getUserData(JSON.parse(cookie.split("=")[1]).token);
      if (user) {
        router.push({ name: "myOrders" });
      } else {
        emitter.emit("loginFirst");
      }
    } else {
      emitter.emit("loginFirst");
    }
  } else {
    router.push({ name: link.route });
  }
};

const sideLinks = ref([
  {
    id: 1,
    img: require("@/assets/side_bar/home.svg"),
    route: "home",
  },
  {
    id: 2,
    title: "My Cart",
    img: require("@/assets/side_bar/cart.svg"),
    route: "cartPage",
  },
  {
    id: 3,
    title: "My Wishlist",
    img: require("@/assets/side_bar/wishlist.svg"),
    route: "wishlistPage",
  },
  {
    id: 4,
    title: "Contact Us",
    img: require("@/assets/side_bar/contact_us.svg"),
    route: "contactUs",
  },
  {
    id: 5,
    title: "About Us",
    img: require("@/assets/side_bar/about_us.svg"),
    route: "aboutUs",
  },
  {
    id: 5,
    title: "Size Guide",
    img: require("@/assets/side_bar/about_us.svg"),
    route: "sizeGuide",
  },
  {
    id: 5,
    title: "All Products",
    img: require("@/assets/side_bar/cart.svg"),
    route: "AllProducts",
  },
  {
    id: 6,
    title: "My Orders",
    img: require("@/assets/side_bar/cart.svg"),
    route: "purchasedPage",
  },
  // {
  //   id: 6,
  //   title: "Our Application",
  //   img: require("@/assets/side_bar/our_application.svg"),
  //   route: "/",
  // },
  // {
  //   id: 7,
  //   title: "Track Your Order",
  //   img: require("@/assets/side_bar/track.svg"),
  //   route: "/",
  // },
]);
// const handleHeight = () => {
//   windowHeight.value = window.scrollY;
// };
onMounted(async () => {
  // window.addEventListener("scroll", handleHeight);
  // handleHeight();
  localStorage.getItem("lenore_language") == "ar"
    ? (getLang.value = true)
    : (getLang.value = false);
  sideLinks.value[0].title = languageServ("home");
  sideLinks.value[1].title = languageServ("myCart");
  sideLinks.value[2].title = languageServ("myWishlist");
  sideLinks.value[3].title = languageServ("contactUs");
  sideLinks.value[4].title = languageServ("aboutUs");
  sideLinks.value[5].title = languageServ("sizeGuide");
  sideLinks.value[6].title = languageServ("products");
  sideLinks.value[7].title = languageServ("myOrders");
  cartModule.getCartItems();
  if (cookie) {
    await loginSt.getUserData(JSON.parse(cookie.split("=")[1]).token);
  }
});
</script>

<style lang="scss" scoped>
.content_parent {
  @media (max-width: 880px) {
    flex-direction: column;
    justify-content: start;
    align-items: start !important;
  }
}
.main_navbar {
  .v-container {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.menu-links {
  .overlay {
    position: relative;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.603);
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
    &.show {
      position: fixed;
      opacity: 1;
      z-index: 999999999999;
    }
  }
  .home-links {
    position: fixed;
    background-color: white;
    height: 100%;
    z-index: 99999999999;
    left: 0;
    top: 0;
    transition: 0.3s ease-in-out;
    transform: translateX(-100%);
    width: 23%;
    padding: 10px;
    opacity: 0;
    @media (max-width: 1199px) {
      width: 28%;
    }
    @media (max-width: 991px) {
      width: 32%;
    }
    @media (max-width: 767px) {
      width: 43%;
    }
    @media (max-width: 620px) {
      width: 55%;
    }
    @media (max-width: 490px) {
      width: 85%;
    }
    &.show {
      transform: translateX(0);
      opacity: 1;
    }
    ul {
      padding: 40px 0 10px 10px;
      li {
        margin-bottom: 20px;
        > div {
          display: flex;
          align-items: end;
          gap: 8px;
          color: var(--cyan);
          font-weight: 500;
          font-size: 18px;
          cursor: pointer;
          img {
            width: 25px;
            margin-bottom: -4px;
          }
        }
      }
    }
  }
}
.home-cart {
  padding: 0;
  font-family: Outfit;
  font-weight: 400 !important;
  .overlay {
    position: relative;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.603);
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
    &.show {
      position: fixed;
      opacity: 1;
      z-index: 999999999999;
    }
  }
  .purchase {
    position: fixed;
    background-color: white;
    height: 100%;
    z-index: 99999999999;
    right: 0;
    top: 0;
    color: var(--cyan);
    width: 400px;
    transition: 0.3s ease-in-out;
    transform: translateX(103%);
    background: #eee;
    @media (max-width: 767px) {
      & {
        width: 60%;
      }
    }
    &.show {
      transform: translateX(0);
    }
    .heading {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--cyan);
      padding: 15px;
      .home-cart-img {
        width: 160px;
        img {
          width: 100%;
        }
      }
      .close-cart {
        cursor: pointer;
        color: white;
        display: flex;
        i {
          font-size: 22px;
        }
      }
      @media (max-width: 767px) {
        & {
          padding: 15px;
          .home-cart-img {
            width: 165px;
          }
          .close-cart {
            i {
              font-size: 23px;
            }
          }
        }
      }
    }
    .products {
      position: relative;
      padding-top: 10px;
      flex: 1;
      overflow-y: scroll;
      color: var(--cyan);
      background: #eee;
      padding: 10px 6px;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(128, 128, 128, 0.404);
        transition: 0.3s;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(128, 128, 128);
      }
      &::-webkit-scrollbar-corner {
        background-color: white;
        box-shadow: 0 0 10px #ddd inset;
      }

      .item {
        display: flex;
        padding: 10px;
        align-items: stretch;
        background: white;
        margin-bottom: 10px;
        border-radius: 9px;
        position: relative;
        overflow: hidden;
        .item_overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgb(255 255 255 / 27%);
          opacity: 1;
          pointer-events: none;
          z-index: 43;
          backdrop-filter: blur(0.5px);
          &.hide {
            display: none;
          }
        }
        .product-img {
          a {
            cursor: pointer;
          }
          img {
            width: 150px;
          }
        }
        .product-text {
          flex: 1;
          a {
            cursor: pointer;
            font-weight: 500;
            color: var(--cyan);
            font-size: 14px;
          }
          p {
            font-weight: 500;
            font-size: 13px;
            margin: 0;
            margin-top: 10px;
          }
        }
        @media (max-width: 767px) {
          & {
            .product-img {
              width: 90px !important;
              img {
                width: 90px !important;
              }
            }
            .product-text {
              .link {
                font-size: 14px !important;
              }
              p {
                font-size: 14px !important;
              }
            }
          }
        }
        .delete {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            border: none;
            background-color: transparent;
            margin-right: 10px;
            i {
              pointer-events: none;
              font-size: 17px;
            }
          }
        }
      }
    }
    @media (max-width: 576px) {
      & {
        .heading {
          .home-cart-img {
            width: 135px !important;
          }
          .close-cart {
            i {
              font-size: 20px;
            }
          }
        }
      }
    }
    @media (max-width: 420px) {
      & {
        width: 85%;
        .heading {
          .home-cart-img {
            width: 135px !important;
          }
          .close-cart {
            i {
              font-size: 20px;
            }
          }
        }
      }
    }
    .empty-cart {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex: 1;
      h2 {
        font-size: 23px;
        font-weight: 400;
      }
      .continue-link {
        a {
          color: var(--cyan);
          font-size: 16px;
          text-decoration: underline;
        }
        &:hover {
          a {
            text-decoration: underline;
          }
        }
      }
    }
    .cart-footer {
      width: 100%;
      padding: 9px;
      color: white;
      z-index: 9;
      border-top: 1px solid var(--cyan);
      color: var(--cyan);
      position: relative;
      .item_overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgb(255 255 255 / 27%);
        opacity: 1;
        pointer-events: none;
        z-index: 3;
      }
      .total {
        font-size: 17px;
        .total-num {
          font-weight: 500;
        }
      }
      .view-cart,
      .pay {
        display: block;
        padding: 8px 30px;
        border-radius: 6px;
        letter-spacing: 0.6px;
        margin: auto;
        text-align: center;
        color: white;
        background-color: var(--cyan);
        margin-top: 10px;
        width: 100%;
      }
      .pay {
        background-color: transparent;
        border: 1px solid var(--cyan);
        color: var(--cyan);
      }
    }
  }
}
.bottom_links {
  @media (max-width: 767px) {
    display: none;
  }
}
ul {
  li {
    a {
      color: white;
    }
  }
}
.navbar_parent {
  position: relative;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background: var(--bg-color);
    animation: 0.9s ease 0s normal both 1 running fixedAnim;
    border-bottom: 1px solid var(--yellow);
    background-color: var(--cyan);
  }
  @keyframes fixedAnim {
    0% {
      top: -100%;
    }
    100% {
      top: 0;
    }
  }
}
.cart_icon {
  .v-badge__wrapper {
    align-items: center;
  }
}
.search_input {
  padding: 7px 10px;
  background-color: white;
  border-radius: 4px;
  flex: 1%;
  max-width: 400px;
  @media (max-width: 880px) {
    max-width: unset;
    width: 100%;
  }
  input {
    color: var(--cyan);
    font-family: Outfit;
    padding-left: 20px;
    max-width: 100%;
    &::placeholder {
      color: grey;
      font-family: Outfit;
      opacity: 0.5;
    }
  }
}
.menu_icon {
  display: none !important;
  flex: 1;
  @media (max-width: 767px) {
    display: block !important;
  }
}
.menu_list {
  @media (max-width: 767px) {
    display: none !important;
  }
}
.login_parent {
  @media (max-width: 880px) {
    width: 100%;
    justify-content: end;
  }
}
</style>
