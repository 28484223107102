<template>
  <div class="added_to_cart">
    <v-dialog v-model="dialog" width="auto" class="img_modal">
      <v-card
        style="
          padding: 50px 10px 110px;
          border: 1px solid var(--cyanMid);
          border-radius: 8px;
        "
      >
        <v-card-title class="px-0">
          <div class="dialog_parent" style="background-color: white">
            <h2
              style="
                text-align: center;
                color: rgba(9, 141, 143, 1);
                font-size: 25px;
              "
            >
              {{ productDetails.name }} added to <br />
              the {{ addedTo }} successfully!
            </h2>
          </div>
        </v-card-title>
        <v-card-text class="pt-0 d-flex justify-center" style="gap: 12px">
          <v-btn
            elevation="0"
            style="
              text-transform: none;
              background-color: var(--cyanMid);
              color: white;
              border-radius: 7px;
              height: 31px;
            "
            @click="dialog = false"
          >
            Continue Shopping
          </v-btn>
          <v-btn
            elevation="0"
            style="
              text-transform: none;
              background-color: var(--cyanMid);
              color: white;
              border-radius: 7px;
              height: 31px;
            "
            @click="goToPage"
          >
            View {{ addedTo }}
          </v-btn>
        </v-card-text>
      </v-card>
      <!-- <div class="dialog_parent" style="background-color: white">
        <v-btn @click="emits('closePopup')"> Close </v-btn>
      </div> -->
    </v-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps, defineEmits, watch } from "vue";
const dialog = ref(false);
const props = defineProps(["dialog", "productDetails", "addedTo"]);
const emits = defineEmits(["closePopup"]);

import { useRouter } from "vue-router";
const router = useRouter();
watch(
  () => dialog.value,
  (newVal) => {
    if (!newVal) {
      setTimeout(() => {
        emits("closePopup");
      }, 10);
    }
  }
);
const goToPage = () => {
  if (props.addedTo == "Cart") {
    router.push({ name: "cartPage" });
    emits("closePopup");
  }
  if (props.addedTo == "Wishlist") {
    router.push({ name: "wishlistPage" });
    emits("closePopup");
  }
};
onMounted(() => {
  dialog.value = props.dialog;
});
</script>
<style lang="scss" scoped>
.dialog_parent {
  color: var(--cyanMid);
  font-weight: 700;
  background-color: white;
}
</style>
