export default {
  yourOrders: "طلباتك",
  empty: "قائمة الطلبات الخاصة بك فارغة!",
  continueShopping: "أكمل التسوق",
  orderLoginFirst: "لم تقم بتسجيل الدخول بعد لعرض طلباتك.",
  login: "تسجيل الدخول",
  signUp: "انشأ حساب",
  order: "طلب",
  products: "منتجات",
  qrInTotal: "ريال قطري في الاجمال",

  // Wishlist
  yourWishtlist: " رغباتك",
};
