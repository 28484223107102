import { defineStore } from "pinia";
import axios from "axios";
import config from "@/config/config";

export const brandsSt = defineStore("brandsSt", {
  state: () => ({
    allBrands: [],
  }),
  actions: {
    async getAllBrands() {
      await axios
        .get(`${config.baseUrl}/brands`, config.headers())
        .then((res) => {
          this.allBrands = res.data.data;
        });
    },
  },
});
