<template>
  <div class="added_to_cart">
    <v-dialog v-model="dialog" width="auto" class="img_modal" persistent>
      <v-card
        style="
          padding: 50px 10px 110px;
          border: 1px solid var(--cyanMid);
          border-radius: 8px;
        "
      >
        <v-card-title class="px-0">
          <div class="dialog_parent" style="background-color: white">
            <h2
              style="
                text-align: center;
                color: rgba(9, 141, 143, 1);
                font-size: 19px;
              "
            >
              You haven't logged in yet, kindly login first
            </h2>
          </div>
        </v-card-title>
        <v-card-text class="pt-0 d-flex justify-center" style="gap: 12px">
          <div>
            <v-btn
              elevation="0"
              style="
                text-transform: none;
                background-color: var(--cyanMid);
                color: white;
                border-radius: 7px;
                height: 31px;
              "
              @click="$router.push({ name: 'signUp' }), (dialog = false)"
            >
              Sign up
            </v-btn>
          </div>
          <div>
            <v-btn
              elevation="0"
              style="
                text-transform: none;
                background-color: var(--cyanMid);
                color: white;
                border-radius: 7px;
                height: 31px;
              "
              @click="$router.push({ name: 'signIn' }), (dialog = false)"
            >
              Login
            </v-btn>
          </div>
        </v-card-text>
        <div
          @click="handlePopup"
          style="
            text-align: center;
            color: var(--cyan);
            font-size: 13px;
            margin-top: 20px;
            cursor: pointer;
          "
        >
          Continue Shopping
        </div>
      </v-card>
      <!-- <div class="dialog_parent" style="background-color: white">
        <v-btn @click="emits('closePopup')"> Close </v-btn>
      </div> -->
    </v-dialog>
  </div>
</template>
<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted, defineProps, defineEmits, watch } from "vue";

const dialog = ref(false);
const props = defineProps(["dialog", "productDetails", "addedTo"]);
const router = useRouter();
const route = useRoute();

const emits = defineEmits(["closePopup"]);

watch(
  () => dialog.value,
  (newVal) => {
    if (!newVal) {
      setTimeout(() => {
        emits("closePopup");
      }, 10);
    }
  }
);
// const goToPage = () => {
//   if (props.addedTo == "Cart") {
//     router.push({ name: "cartPage" });
//     emits("closePopup");
//   }
//   if (props.addedTo == "Wishlist") {
//     router.push({ name: "wishlistPage" });
//     emits("closePopup");
//   }
// };
const handlePopup = () => {
  if (route.name == "cartPage") {
    router.push({ name: "home" });
  } else {
    emits("closePopup");
  }
};
onMounted(() => {
  dialog.value = props.dialog;
});
</script>
<style lang="scss" scoped>
.dialog_parent {
  color: var(--cyanMid);
  font-weight: 700;
  background-color: white;
}
</style>
