<template>
  <div class="home-cart">
    <div class="overlay" ref="cartOverlay" @click="emit('closeModal')"></div>
    <div class="purchase d-flex flex-column" ref="cartPurchase">
      <div
        class="home-cart-img text-center"
        style="border-bottom: 1px solid var(--cyan); margin-top: 6px"
      >
        <router-link @click="emit('closeModal')" to="/">
          <img style="width: 32px" src="@/assets/home/menu_logo.svg" alt="" />
        </router-link>
      </div>
      <div class="products" v-if="cartItems && cartItems.length">
        <div class="item" v-for="item in cartItems" :key="item.id">
          <div class="product-img" style="margin-right: 12px">
            <div
              style="
                width: 80px;
                height: 80px;
                display: block;
                border-radius: 9px;
                overflow: hidden;
              "
              @click="!loading ? emit('closeModal') : ''"
            >
              <img :src="item.default_image" style="max-width: 80px" alt="" />
            </div>
          </div>
          <div class="product-text">
            <router-link
              :to="{ name: 'productDetails', params: { id: item.id } }"
              class="link"
              @click="!loading ? emit('closeModal') : ''"
            >
              {{ item.name }}
            </router-link>
            <p class="total">
              {{ parseInt(item.default_price) * item.number }} QR
            </p>
            <div class="total mt-2">
              <v-icon
                class="me-2"
                @click="cartModule.decreaseNum(item)"
                :style="`${
                  item.number <= 1 ? 'opacity: 0.3; cursor: not-allowed' : ''
                }`"
                >mdi-minus</v-icon
              >
              {{ item.number }}
              <v-icon
                class="ms-2"
                @click="cartModule.increaseItems(item)"
                :style="`${
                  item.number >= item.quantity
                    ? 'opacity: 0.3; cursor: not-allowed'
                    : ''
                }`"
                >mdi-plus</v-icon
              >
            </div>
          </div>
          <div
            class="delete"
            style="cursor: pointer"
            @click="!loading ? deleteItem($event, item) : ''"
          >
            <img
              style="width: 15px"
              src="@/assets/wishlist/delete.svg"
              alt=""
            />
          </div>

          <div class="item_overlay hide" :id="`overlay_${item.id}`"></div>
        </div>
      </div>
      <div
        style="
          flex: 1;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-top: 130px;
        "
        v-if="cartItems && !cartItems.length"
      >
        <h2
          v-if="cartItems && !cartItems.length"
          style="font-size: 23px; font-weight: 400; margin-bottom: 10px"
        >
          Your cart is empty!
        </h2>
        <button
          v-if="cartItems && !cartItems.length"
          @click="emit('closeModal')"
          style="text-decoration: underline"
        >
          Continue Shopping
        </button>
      </div>
      <div class="cart-footer" v-if="cartItems && cartItems.length">
        <!-- v-if="cartItems && cartItems.length && !loadingItems" -->
        <div class="total text-center">
          <span class="sub m-2"> Subtotal </span>
          <span class="total-num">{{ totalPrice }} QR</span>
        </div>
        <div class="taxes text-center" style="font-size: 13px">
          Note: Taxes and shipping calculated at checkout
        </div>
        <div>
          <div
            @click="!loading ? toShoppingCart() : ''"
            class="view-cart"
            style="cursor: pointer"
          >
            Checkout
          </div>
        </div>
        <div>
          <button @click="!loading ? emit('closeModal') : ''" class="pay">
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
  </div>
  <LoginFirst
    :dialog="loginModal"
    v-if="loginModal"
    @closePopup="loginModal = false"
  />
</template>
<script setup>
import { ref, defineProps, watch, nextTick, defineEmits, computed } from "vue";
import { cartStore } from "@/stores/addToCart.js";
import { storeToRefs } from "pinia";
import { checkCookie } from "@/stores/auth.js";
import LoginFirst from "@/components/global/LoginFirst.vue";
import { useRouter } from "vue-router";

// Init Store
const cookie = decodeURIComponent(document.cookie);
const cartModule = cartStore();
const { user } = storeToRefs(checkCookie());
const { cartItems } = storeToRefs(cartModule);
const props = defineProps(["openModal"]);
const emit = defineEmits(["closeModal"]);

const loginModal = ref(false);
const isCartOpen = ref(false);
const loading = ref(false);
const cartPurchase = ref(null);
const cartOverlay = ref(null);
const router = useRouter();

const toggleCart = async () => {
  isCartOpen.value = !isCartOpen.value;
  if (isCartOpen.value) {
    nextTick(() => {
      cartOverlay.value.classList.add("show");
      cartPurchase.value.classList.add("show");
    });
  } else {
    cartPurchase.value.classList.remove("show");
    cartOverlay.value.classList.remove("show");
    emit("closeModal");
  }
};

const deleteItem = async ($event, item) => {
  let el = document.querySelector(`#overlay_${item.id}`);
  el.classList.remove("hide");
  $event.target.parentNode.classList.add("changeParent");

  setTimeout(() => {
    cartModule.removeItem(item);
    el.classList.add("hide");
  }, 1000);
};

const toShoppingCart = async () => {
  if (cookie) {
    await checkCookie().getUserData(JSON.parse(cookie.split("=")[1]).token);
    if (!user.value) {
      loginModal.value = true;
    } else {
      router.push({ name: "cartPage" });
    }
  } else {
    loginModal.value = true;
  }
  emit("closeModal");
};

watch(
  () => props.openModal,
  () => {
    toggleCart();
  }
);

const totalPrice = computed(() => {
  let price = 0;
  if (cartItems.value.length) {
    cartItems.value.forEach((e) => {
      price += e.number * e.default_price;
    });
  }
  return price;
});
</script>
<style lang="scss" scoped>
.home-cart {
  padding: 0;
  font-family: Outfit;
  font-weight: 400 !important;
  .overlay {
    position: relative;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.603);
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
    &.show {
      position: fixed;
      opacity: 1;
      z-index: 999999999999;
    }
  }
  .purchase {
    position: fixed;
    background-color: white;
    height: 100%;
    z-index: 99999999999;
    right: 0;
    top: 0;
    color: var(--cyan);
    width: 400px;
    transition: 0.3s ease-in-out;
    transform: translateX(103%);
    background: #eee;
    @media (max-width: 767px) {
      & {
        width: 60%;
      }
    }
    &.show {
      transform: translateX(0);
    }
    .heading {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--cyan);
      padding: 15px;
      .home-cart-img {
        width: 160px;
        img {
          width: 100%;
        }
      }
      .close-cart {
        cursor: pointer;
        color: white;
        display: flex;
        i {
          font-size: 22px;
        }
      }
      @media (max-width: 767px) {
        & {
          padding: 15px;
          .home-cart-img {
            width: 165px;
          }
          .close-cart {
            i {
              font-size: 23px;
            }
          }
        }
      }
    }
    .products {
      position: relative;
      padding-top: 10px;
      flex: 1;
      overflow-y: scroll;
      color: var(--cyan);
      background: #eee;
      padding: 10px 6px;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(128, 128, 128, 0.404);
        transition: 0.3s;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(128, 128, 128);
      }
      &::-webkit-scrollbar-corner {
        background-color: white;
        box-shadow: 0 0 10px #ddd inset;
      }

      .item {
        display: flex;
        padding: 10px;
        align-items: stretch;
        background: white;
        margin-bottom: 10px;
        border-radius: 9px;
        position: relative;
        overflow: hidden;
        .item_overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgb(255 255 255 / 27%);
          opacity: 1;
          z-index: 43;
          backdrop-filter: blur(0.5px);
          &.hide {
            display: none;
          }
        }
        .product-img {
          a {
            cursor: pointer;
          }
          img {
            width: 150px;
          }
        }
        .product-text {
          flex: 1;
          a {
            cursor: pointer;
            font-weight: 500;
            color: var(--cyan);
            font-size: 14px;
          }
          p {
            font-weight: 500;
            font-size: 13px;
            margin: 0;
            margin-top: 10px;
          }
        }
        @media (max-width: 767px) {
          & {
            .product-img {
              width: 90px !important;
              img {
                width: 90px !important;
              }
            }
            .product-text {
              .link {
                font-size: 14px !important;
              }
              p {
                font-size: 14px !important;
              }
            }
          }
        }
        .delete {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            border: none;
            background-color: transparent;
            margin-right: 10px;
            i {
              pointer-events: none;
              font-size: 17px;
            }
          }
        }
      }
    }
    @media (max-width: 576px) {
      & {
        .heading {
          .home-cart-img {
            width: 135px !important;
          }
          .close-cart {
            i {
              font-size: 20px;
            }
          }
        }
      }
    }
    @media (max-width: 420px) {
      & {
        width: 85%;
        .heading {
          .home-cart-img {
            width: 135px !important;
          }
          .close-cart {
            i {
              font-size: 20px;
            }
          }
        }
      }
    }
    .empty-cart {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex: 1;
      h2 {
        font-size: 23px;
        font-weight: 400;
      }
      .continue-link {
        a {
          color: var(--cyan);
          font-size: 16px;
          text-decoration: underline;
        }
        &:hover {
          a {
            text-decoration: underline;
          }
        }
      }
    }
    .cart-footer {
      width: 100%;
      padding: 9px;
      color: white;
      z-index: 9;
      border-top: 1px solid var(--cyan);
      color: var(--cyan);
      position: relative;
      .item_overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgb(255 255 255 / 27%);
        opacity: 1;
        pointer-events: none;
        z-index: 3;
      }
      .total {
        font-size: 17px;
        .total-num {
          font-weight: 500;
        }
      }
      .view-cart,
      .pay {
        display: block;
        padding: 8px 30px;
        border-radius: 6px;
        letter-spacing: 0.6px;
        margin: auto;
        text-align: center;
        color: white;
        background-color: var(--cyan);
        margin-top: 10px;
        width: 100%;
      }
      .pay {
        background-color: transparent;
        border: 1px solid var(--cyan);
        color: var(--cyan);
      }
    }
  }
}
.bottom_links {
  @media (max-width: 767px) {
    display: none;
  }
}
ul {
  li {
    a {
      color: white;
    }
  }
}
</style>
