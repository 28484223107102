import { defineStore } from "pinia";
import axios from "axios";
import config from "@/config/config";
import { googleSdkLoaded } from "vue3-google-login";

export const checkCookie = defineStore("checkCookie", {
  state: () => ({
    coockie: null,
    user: null,
    isLoggedIn: false,
    resetToken: false,
    countriesCodes: [],
  }),
  actions: {
    setCookie(value, timeout) {
      let expires = "";
      let date = new Date();
      date.setTime(date.getTime() + timeout);
      expires = "; expires=" + date.toUTCString() + `;path=/`;
      document.cookie = "Lenore" + "=" + JSON.stringify(value) + expires;
    },
    async doLogIn(userData) {
      this.authErrors = [];
      let isLogged;
      await axios
        .post(`${config.baseUrl}/login`, userData, config.headers(true))
        .then((res) => {
          if (res.data.pass) {
            this.user = res.data;
            this.setCookie(
              {
                logger: res.data.data.user.id,
                token: res.data.data.token,
              },
              60 * 60 * 1000 * 72
            );
            isLogged = true;
          }
        })
        .catch(() => {
          isLogged = false;
        });
      return isLogged;
    },
    async getUserData(cookie) {
      let result;
      await axios
        .get(`${config.baseUrl}/profile`, config.headers(true))
        .then((res) => {
          this.isLoggedIn = true;
          this.user = res.data;
          this.user.token = cookie;
          result = true;
        })
        .catch(() => {
          result = false;
        });
      return result;
    },
    async handleLogout() {
      this.authUser = null;
      this.setCookie({}, 0);
      this.user = null;
    },
    async doSignUp(userData) {
      let result;
      await axios
        .post(`${config.baseUrl}/create-account`, userData, config.headers())
        .then(() => {
          result = true;
        })
        .catch((err) => {
          result = err;
        });
      return result;
    },
    async doResetPassword(formData) {
      await axios
        .post(`${config.baseUrl}/email-check`, formData, config.headers())
        .then((res) => {
          this.resetToken = res.data.data.email_reset_token;
        });
    },
    async getCountriesCodes() {
      await axios
        .get(`${config.baseUrl}/countries-codes`, config.headers())
        .then((res) => {
          this.countriesCodes = res.data.data;
        });
    },
    async sendCodeToBackend(code, callBack) {
      await axios
        .post("https://oauth2.googleapis.com/token", {
          code,
          client_id:
            "198734624253-tqkjaqbmqrn7r68ceu19uuf1gg85r1oi.apps.googleusercontent.com",
          client_secret: "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",
          redirect_uri: "postmessage",
          grant_type: "authorization_code",
        })
        .then(async (res) => {
          const accessToken = res.data.access_token;
          await axios
            .get("https://www.googleapis.com/oauth2/v3/userinfo", {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then(async (userResponse) => {
              if (userResponse && userResponse.data) {
                let result;
                await axios
                  .post(
                    `${config.baseUrl}/google-account`,
                    {
                      token: res.data.id_token,
                    },
                    config.headers()
                  )
                  .then((res) => {
                    result = true;
                    this.user = res.data;
                    this.setCookie(
                      {
                        logger: res.data.data.user.id,
                        token: res.data.data.token,
                      },
                      60 * 60 * 1000 * 72
                    );
                    callBack(true);
                  })
                  .catch((err) => {
                    console.error("Token exchange failed:", err);
                    result = false;
                    callBack(false);
                  });
                return result;
              }
            })
            .catch((err) => {
              console.error("Token exchange failed:", err);
            });
        })
        .catch((error) => {
          console.error("Token exchange failed:", error);
        });
    },
    async googleLogin(callBack) {
      googleSdkLoaded((google) => {
        google.accounts.oauth2
          .initCodeClient({
            client_id:
              "198734624253-tqkjaqbmqrn7r68ceu19uuf1gg85r1oi.apps.googleusercontent.com",
            scope: "email profile openid",
            redirect_uri: "http://localhost:8080",
            callback: (response) => {
              if (response.code) {
                this.sendCodeToBackend(response.code, callBack);
              }
            },
          })
          .requestCode();
      });
    },
  },
});
