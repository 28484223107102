import LanguageService from "@/stores/LanguageService";

const config = {
  baseUrl: `https://main.lenore.qa/api/app`,
  headers: (hasLogin = null) => {
    return {
      headers: hasLogin
        ? {
            Authorization: `Bearer ${
              document.cookie
                ? JSON.parse(decodeURIComponent(document.cookie).split("=")[1])
                    .token
                : ""
            }`,
            LANG: LanguageService.currentLanguage(),
          }
        : {
            LANG: LanguageService.currentLanguage(),
          },
    };
  },
};

export default config;
